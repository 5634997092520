export enum URL {
  AddCardStaging = 'https://admin-staging.getfaveo.com/Billing',
  AddCardDevelopment = 'https://admin-dev.getfaveo.com/Billing',
  checkoutStaging = 'https://admin-staging.getfaveo.com/JobDetailsReview',
  checkoutDevelopment = 'https://admin-dev.getfaveo.com/JobDetailsReview',
  addHelpmateBankAccount = 'https://admin-dev.getfaveo.com/HelpmateAccountSetupSuccess',
  addHelpmatePaymentDetails = 'https://admin-dev.getfaveo.com/HelpmatePaymentDetails',
  // prod urls
  AddCardProduction = 'https://app.getfaveo.com/Billing',
  checkoutAddCardProduction = 'https://app.getfaveo.com/JobDetailsReview',
  checkoutDevelopmentProduction = 'https://app.getfaveo.com/JobDetailsReview',
  addHelpmateBankAccountProduction = 'https://app.getfaveo.com/HelpmateAccountSetupSuccess',
  addHelpmatePaymentDetailsProduction = 'https://app.getfaveo.com/HelpmatePaymentDetails',
}

export enum STRIPE_PATHS {
  jobConfirmPath = 'https://admin-dev.getfaveo.com/ConfirmJob',
  jobPostConfirmPath = 'https://admin-dev.getfaveo.com/JobPostConfirm',

  // prod urls
  jobConfirmPathProduction = 'https://app.getfaveo.com/ConfirmJob',
  jobPostConfirmPathProd = 'https://app.getfaveo.com/JobPostConfirm',
}

export enum STRIPE_SECRETKEY {
  secretkey = 'pk_test_jecivkz0ML6MXai3yfhNdS3d00MrxIs6hk',
}
